
/**
 * @name: 推广员管理-推广员信息管理
 * @author: itmobai
 * @date: 2024-01-11 17:32
 * @description： 推广员管理-推广员信息管理
 * @update: 2024-01-11 17:32
 */
import { Vue, Component } from "vue-property-decorator"
import config from "@/config";
import {promoterCreateApi, promoterDetailApi, promoterModifyApi, promoterQueryApi} from "@/apis/promoter/list"
import { IPromoterList, IPromoterListParam } from "@/apis/promoter/list/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy} from "@/utils/common";

@Component({})
export default class promoterListPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IPromoterList[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPromoterListParam = {
    page: 1,
    limit: 10,
    promoterTypes: 1
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    dialogWidth: '800px',
    delBtn: false,
    column: [
      {
        label: "推广员",
        prop: "keyWord",
        search: true,
        placeholder: "推广员ID/姓名/联系方式",
        hide: true,
        addHide: true,
        editHide: true
      },
      {
        label: "推广员ID",
        prop: "promoterId",
        align: "left",
        width: 180,
        addHide: true,
        editHide: true
      },
      {
        label: "姓名",
        prop: "promoterName",
        align: "center",
        overHidden: true,
        maxlength: 20,
        rules: [
          {required: true, message: '姓名不能为空!', trigger: 'blur'}
        ],
        span: 12
      },
      {
        label: "联系方式",
        prop: "promoterPhone",
        align: "center",
        width: 150,
        maxlength: 11,
        rules: [
          {required: true, message: '联系方式不能为空!', trigger: 'blur'},
          {
            validator: (rule: any, value: any, callback: any) => {
              if (!/^1[3-9]\d{9}$/.test(value)) {
                return callback(new Error("请输入有效的手机号"))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        span: 12
      },
      {
        label: "状态",
        prop: "promoterStatus",
        align: "center",
        type: "select",
        search: true,
        dicData: [
          {
            label: "启用",
            value: 1
          },
          {
            label: "禁用",
            value: 2
          },
        ],
        addHide: true,
        editHide: true,
        slot: true
      },
      {
        label: "【藏地粮市】邀请二维码",
        prop: "cappletCode",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        addHide: true,
        editHide: true,
        width: 160
      },
      {
        label: "【藏地粮市尊享】邀请二维码",
        prop: "mbAppletCode",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        addHide: true,
        editHide: true,
        width: 180,
        hide: true,
      },
      {
        label: "【藏地粮市】邀请链接",
        prop: "promoterSn",
        addHide: true,
        editHide: true,
        overHidden: true,
        align: "center",
        slot: true,
        width: 200,
        viewHide: true
      },
      {
        label: "说明",
        prop: "remarks",
        align: "center",
        type: "textarea",
        showWordLimit: true,
        overHidden: true,
        maxlength: 100,
        span: 24
      },
      {
        label: "创建时间",
        prop: "addTime",
        type: "daterange",
        align: "center",
        search: true,
        addHide: true,
        editHide: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: IPromoterListParam = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    promoterQueryApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  rowSave (form: IPromoterList, done: Function, loading: Function) {
    promoterCreateApi({...form, promoterTypes: 1}).then(e => {
      if (e) {
        this.getList()
        done()
        this.$message.success("操作成功!")
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IPromoterList, done: Function, loading: Function) {
    promoterModifyApi(form).then(e => {
      if (e) {
        this.getList()
        done()
        this.$message.success("操作成功!")
      }
    }).finally(() => {
      loading()
    })
  }

  statusSwitch (promoterId: string, promoterStatus: number) {
    promoterModifyApi({promoterId, promoterStatus}).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
      }
    })
  }

  openEdit (row: IPromoterList, index: number) {
    promoterDetailApi(row.promoterId).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
    })
  }

  created () {
    this.getList()
  }
}
